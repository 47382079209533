.productCvSelect {
  background-color: #fff;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #c8c8c8;
}

.productCvLabel {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px !important;
  width: 100%;
  margin-right: 0;
  display: block;
}

.productCvLabel::before {
  top: 15px !important;
  left: 15px !important;
}

.productCvLabel::after {
  top: 21px !important;
  left: 23px !important;
}

.productCvBorder {
  padding: 0 20px 20px 20px;
}

.productCvPrice {
  display: table;
  width: 100%;
}

.productCvPriceSubtotalText,
.productCvPriceSubtotalValue {
  display: table-cell;
  vertical-align: middle;
}
