.checkout {
  border-radius: 20px;
  background-color: #fff;
  @media screen and (min-width: 1036px) {
    padding: 40px;
  }
}

.option {
  position: relative;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.radioInput + label::before {
  bottom: 0;
}

.radioInput + label::after {
  top: 17px;
}

.selected {
  border: 2px solid #f3c959;
}

.credit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.xlTitle {
  font-size: 1.5rem !important;
}

.lTitle {
  font-size: 1.25rem !important;
}

.buttonArrow {
  margin-left: 5px;
}
