.container {
  border-radius: 20px;
  background-color: #fff;
  padding: 40px;

  @media screen and (max-width: 978px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}
