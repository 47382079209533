.container {
  border-radius: 20px;
  background-color: #fff;
  padding: 40px;
  @media screen and (max-width: 1036px) {
    padding: 0;
  }
}

.block {
  border-radius: 10px;
  border: #fac83c 4px solid;
  padding-left: 10px;
  padding-right: 10px;
}

.triangle {
  display: block;
  width: 22px;
  height: 25px;
  margin: 4px auto 0 auto;
}
