.container {
  margin: 0 auto;
  width: 100%;
}

.productSinglePhoto {
  margin: 0 auto;
  min-height: 1px;
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 30px;
}

.featureRowImage {
  display: block;
  margin: 0 auto;
  width: 100%;
}
