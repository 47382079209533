.thumbnailsContainer {
  position: relative;
  text-align: center;
  margin-top: 30px;
  @media screen and (max-width: 1036px) {
    margin-top: 30px;
  }
}

// 元々のvuejsのソースコードに従って、750pxは境名としている
.hidePC {
  @media screen and (min-width: 751px) {
    display: none;
  }
}
.hideSP {
  @media screen and (max-width: 750px) {
    display: none;
  }
}

.sliderPrev {
  left: -28px;
}

.sliderNext {
  right: -28px;
}
