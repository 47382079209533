.button {
  position: absolute;
  top: 30px;
  transform: translateY(-50%);
  opacity: 1;
  color: #fff;
  padding: 15px 0 !important;
  text-align: center !important;
  background-color: #fac83c !important;
  width: 30px !important;
  border: 0;
  margin: 0;
}

.button:hover,
.button:focus {
  opacity: 0.6;
}

.button:hover,
.button:focus {
  opacity: 1 !important;
}

.button[disabled]:hover,
.button[disabled]:focus {
  opacity: 0.5 !important;
}

.icon {
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
}

.iconText {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
