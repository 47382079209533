.container {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -22px;
}

.item {
  float: left;
  padding-left: 30px;
  width: 25%;
}

.button {
  display: block;
  margin: -2px 0 8px;
  border: 2px solid transparent;
  background: none;
  padding: 0;
  width: 100%;
}

@media only screen and (max-width: 749px) {
  .item {
    display: inline-block;
    width: 72px;
    float: none;
    vertical-align: middle;
    padding: 5px 0 10px;
  }

  .button {
    display: inline;
    border: 0 none;
    background: none;
    padding: 0;
    margin: 0 auto;
    width: 50px;
  }
}
